import React, { Component } from "react";
import { Image, Button } from "react-bootstrap";
import user from "../../images/user.png";
// import edit from "../../images/edit.svg";
import { firestore, storage } from "../../utils/firebaseCONFIG";

export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      image: "",
    };
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.setState({ displayName: this.props.currentUser.displayName });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.setState({ displayName: this.props.currentUser.displayName });
    }
  }

  handleNameChange = async () => {
    firestore.collection("users").doc(this.props.currentUser.id).update({
      displayName: this.state.displayName,
    });
  };

  onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files[0]);
      let image = URL.createObjectURL(event.target.files[0]);

      const ref = storage.ref().child(event.target.files[0].name);

      ref.put(image).then(async (snapshot) => {
        console.log(
          "Uploaded a blob or file!",
          await snapshot.ref.getDownloadURL()
        );
      });

      this.setState({
        image,
      });
      // firestore
      //   .collection("users")
      //   .doc(this.props.currentUser.id)
      //   .update({
      //     photoURL: image,
      //   })
      //   .then(() => {
      //     console.log("success");
      //   })
      //   .catch((error) => {
      //     console.log("error", error);
      //   });
    }
  };

  render() {
    console.log(this.props);
    return (
      <>
        <div className="text-center user-cricle mb-4">
          <div className="mx-auto user-edit position-relative">
            {this.state.image ? (
              <Image src={this.state.image} alt="user" className="rounded" />
            ) : (
              <Image src={user} alt="user" className="rounded" />
            )}

            
          </div>
        </div>
        <div className="user-name text-center mb-4">
          <h3 className="position-relative">
             
             
          </h3>
          {this.props.isEditing ? (
            ""
          ) : (
            <Button
              variant="link"
              onClick={() => {
                this.props.toggleIsEditing();
              }}
            >
              Edit Profile
            </Button>
          )}
        </div>
      </>
    );
  }
}
