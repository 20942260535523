import React, { Component } from "react";
import SidebarMenu from "./SidebarMenu";
import UserProfile from "./UserProfile";
import UserProfilePassword from "./UserProfilePassword";
import { ChevronLeftIcon } from "@primer/octicons-react";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isEditingProfile: false,
    };
  }

  toggleIsEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
    this.setState({ isEditingProfile: false });
  };
  editToggle = () => {
    this.setState({ isEditingProfile: !this.state.isEditingProfile });
  };

  render() {
    return (
      <>
        <div className="sidebar p-4 pt-5">
          {this.state.isEditing ? (
            <div className="back" onClick={this.toggleIsEditing}>
              <span>
                <ChevronLeftIcon size={16} />
              </span>
            </div>
          ) : (
            ""
          )}
          <UserProfile
            toggleIsEditing={this.toggleIsEditing}
            isEditingProfile={this.state.isEditingProfile}
            isEditing={this.state.isEditing}
            editToggle={this.editToggle}
            currentUser={this.props.currentUser}
          />
          {this.state.isEditing ? (
            <UserProfilePassword currentUser={this.props.currentUser} />
          ) : (
            <SidebarMenu />
          )}
        </div>
      </>
    );
  }
}
