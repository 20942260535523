import React, { Component } from "react";
import Header from "./Header";
import { Container, Card, Offcanvas } from "react-bootstrap";
import { ThreeBarsIcon } from "@primer/octicons-react";
import Sidebar from "../sidebar/Sidebar";
import { withRouter } from "react-router-dom";
import { getSearchData } from "../../utils/fetchAPI";
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      title: "",
      urltitle: "",
      content: "",
      searchdata: [],
      totalResult:0
    };
  }
  handleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  componentDidMount = async () => {
    try {
      // console.log("props", this.props);
      const result = await getSearchData(
        `search`,
        this.props.match.params.query
      );

      this.setState({ searchdata: result.data.results,totalResult: result.data.meta.page.total_results });
    } catch (error) {
      console.log(error, error.response);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.match.params.query !== this.props.match.params.query) {
      try {
        // console.log("props", this.props);
        const result = await getSearchData(
          `search`,
          this.props.match.params.query
        );

        this.setState({ searchdata: result.data.results,totalResult: result.data.meta.page.total_results });
      } catch (error) {
        console.log(error, error.response);
      }
    }
  };
  render() {
    console.log("searchdata",this.state.searchdata)
     
    return (
      <>
        <Offcanvas
          className="openoffset"
          show={this.state.isOpen}
          onHide={this.handleOpen}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>

          <Sidebar />
        </Offcanvas>
        <Header currentUser={this.props} />
        <div className="search-bg py-4">
          <span onClick={this.handleOpen} className="toggle-open">
            <ThreeBarsIcon size={32} />
          </span>
          <Container>
            <Card className="radius-30 border-0 pb-4 card-box">
            <div className={`p-4 border-bottom test`} >About <em>{this.state.totalResult} </em> results found</div>
              {this.state.searchdata.map((sdata, index) => (
                <div className={`p-4 border-bottom test`} key={index}>
                  
                  <h5>
                    <a href={sdata.url.raw} target="_blank" rel="noreferrer">{sdata.title.raw}</a>
                  </h5>
                  {sdata.body_content.raw.substring(1, 555) }
                </div>
              ))}
            </Card>
          </Container>
        </div>
      </>
    );
  }
}
export default withRouter(Search);
