// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

// export const config = {
//   apiKey: "AIzaSyBb5JeuXj5UtIVdsVLk04wXgxDZubTWewM",
//   authDomain: "disciple-cc48a.firebaseapp.com",
//   projectId: "disciple-cc48a",
//   storageBucket: "disciple-cc48a.appspot.com",
//   messagingSenderId: "63802566624",
//   appId: "1:63802566624:web:eef3b8ef20dd390ffded85",
// };

const config = {
  apiKey: "AIzaSyCoZtqS69D7YoDwb7E3N_51iTN8TP5GshU",
  authDomain: "disciple-platform.firebaseapp.com",
  projectId: "disciple-platform",
  storageBucket: "disciple-platform.appspot.com",
  messagingSenderId: "456272220167",
  appId: "1:456272220167:web:c553a11487bc895f161aa2",
  measurementId: "G-XEH5SMJGJY"
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return null;

  //firestore.doc it takes a collection path and id coloumn
  const userRef = firestore.doc(`users/${userAuth.uid}`);
  //get method is used to retrive data of uid
  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        //set method is used for signup with user
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (err) {
      console.log("error created user", err.message);
    }
  }
  return userRef;
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const authFun = firebase.auth;
export const firestore = firebase.firestore();
export const database = firebase.database(); 
export const storage = firebase.storage(); 
