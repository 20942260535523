import axios from "axios";
const SERVER_URL = "https://api.ondisciple.com";
const Elastic_SERVER_URL =
  "https://disciple-knowledge-base.ent.us-west1.gcp.cloud.es.io/api/as/v1/engines/disciple-knowledge-graph";

export const getData = async (api) => {
  try {
    const result = await axios({
      method: "GET",
      url: `${SERVER_URL}/${api}`,
      headers: {
        accept: "application/json",
        "x-disciple-token": "dev-Qh0y_fg9jIiMwfpgxGAzAQ",
      },
    });
    return result;
  } catch (error) {
    throw error;
  }
};

export const postData = async (api, data) => {
  try {
    const result = await axios({
      method: "post",
      url: `${SERVER_URL}/${api}`,
      headers: {
        accept: "application/json",
        "x-disciple-token": "dev-Qh0y_fg9jIiMwfpgxGAzAQ",
      },
      data,
    });
    return result;
  } catch (error) {
    return error;
  }
};

export const putData = async (api, data) => {
  try {
    const result = await axios({
      method: "put",
      url: `${SERVER_URL}/${api}`,
      headers: {
        accept: "application/json",
        "x-disciple-token": "dev-Qh0y_fg9jIiMwfpgxGAzAQ",
      },
      data,
    });
    return result;
  } catch (error) {
    return error;
  }
};

export const getSearchData = async (api, data) => {
  try {
    const result = await axios({
      method: "POST",
      url: `${Elastic_SERVER_URL}/${api}`,
      headers: {
        accept: "application/json",
        Authorization: "Bearer search-ax7wv3rskm4z3e491hzermt5",
      },
      data: {
        query: `${data}`,
      },
    });
    return result;
  } catch (error) {
    throw error;
  }
};
