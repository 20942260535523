import React, { Component } from "react";
import {
  Image,
  Form,
  InputGroup,
  FormControl,
  Row,
  Offcanvas,
  Col,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

import logo1 from "../images/logo-2.png";
import search from "../images/search.png";
import jlogo from "../images/jlogo.png";
import Sidebar from "./sidebar/Sidebar";
import { ThreeBarsIcon } from "@primer/octicons-react";
import { getData } from "../utils/fetchAPI";
import { auth } from "../utils/firebaseCONFIG";
// import { Button } from "bootstrap";

const arrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.22 2.97a.75.75 0 011.06 0l4.25 4.25a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06-1.06l2.97-2.97H3.75a.75.75 0 010-1.5h7.44L8.22 4.03a.75.75 0 010-1.06z"
    ></path>
  </svg>
);

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      matches: window.matchMedia("(min-width: 992px)").matches,
      currentJourney: [],
      isSearch: false,
      searchQuery: "",
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    try {
      await auth.signInWithEmailAndPassword(email, password);
      this.setState({ email: "", password: "" });
    } catch (err) {
      console.log("error", err);
    }
  };

  componentDidMount = async () => {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 992px)").addListener(handler);

    try {
      const result = await getData("operator/journeys/?complete=false");
      this.setState({ currentJourney: result.data.journeys });
    } catch (error) {
      console.log(error);
    }
  };

  handleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  subString = (str) => {
    if (str.length > 175) {
      return str.substring(0, 175) + "...";
    }
    return str;
  };

  handleKeyPress = (e) => {
    e.preventDefault();
    // console.log("e: ", e.key);
    if (e.key === "Enter") {
      this.setState({ isSearch: true });
    } else {
      this.setState({ searchQuery: this.state.searchQuery + e.key });
    }
  };

  render() {
    if (this.state.isSearch) {
      return <Redirect to={`/search/${this.state.searchQuery}`} />;
    }
    return (
      <>
        <div className="dashboard">
          <Offcanvas
            className="openoffset"
            show={this.state.isOpen}
            onHide={this.handleOpen}
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>

            <Sidebar currentUser={this.props.currentUser} />
          </Offcanvas>
          {this.state.matches && (
            <Sidebar currentUser={this.props.currentUser} />
          )}
          {!this.state.matches && (
            <span
              onClick={this.handleOpen}
              className="toggle-open mt-5 position-absolute"
            >
              <ThreeBarsIcon size={32} />
            </span>
          )}

          <div className="content p-4">
            <div className="text-center pt-5 col-lg-6 mx-auto">
              <Image src={logo1} alt="" className="mb-5" />
              <div className="search-box mb-5">
                <Form>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <Image src={search} />
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Seach..."
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={(e) =>
                        this.setState({ searchQuery: e.target.value })
                      }
                      value={this.state.searchQuery}
                      onKeyPress={this.handleKeyPress}
                    />
                  </InputGroup>
                </Form>
              </div>
            </div>
            <div className="header-title text-center border-bottom pb-2">
              <h2>Current Journeys</h2>
            </div>
            <div className="view-journeys pt-4">
              <Row>
                {this.state.currentJourney.map((journey, index) => (
                  <Col lg={6} key={index}>
                    <Row className="g-0">
                      <Col lg={5} className="mb-3">
                        <div className="blue-light p-4 h-100">
                          <Image src={jlogo} alt="" className="mb-4" />
                          <h2>{journey.name}</h2>
                        </div>
                      </Col>
                      <Col lg={7} className="mb-lg-24-minus mb-3">
                        <div className="text-jourenty p-4 h-100">
                          <em>
                            Started Date :{" "}
                            {new Date(journey.started).toDateString()}
                          </em>
                          <p>{this.subString(journey.description)}</p>
                          <Link to={`/journey-details/${journey.name}`}>
                            View {arrow}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
              <div className="text-center mt-3">
                <Link className="btn btn-primary" to={`/journey/`}>
                  View All
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
