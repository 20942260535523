import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { LockIcon } from "@primer/octicons-react";
import { authFun, auth } from "../../utils/firebaseCONFIG";
export default class UserProfilePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPasword: "",
      oldPassword: "",
    };
  }
  validatePassword = () => {
    if (this.state.password === this.state.confirmPasword) {
      return true;
    }
    return false;
  };
  handlePasswordChange = async (e) => {
    e.preventDefault();
    const isValid = this.validatePassword();
    if (!isValid) {
      console.log("Invalid new password");
      return;
    }
    const user = auth.currentUser;
    console.log("crd: ", {
      email: this.props.currentUser.email,
      password: this.state.oldPassword,
    });
    const credential = authFun.EmailAuthProvider.credential(
      this.props.currentUser.email,
      this.state.oldPassword
    );
    
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        // User re-authenticated.
        auth.currentUser
          .updatePassword(this.state.password)
          .then(() => {
            console.log("success");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        // An error ocurred
        // ...
        console.log("invlaidOldPass", error);
      });
  };

  render() {
    return (
      <>
        <div className="password-box">
          <h3 className="mb-3">Change Password</h3>
          <Form className="user-password" onSubmit={this.handlePasswordChange}>
            <Form.Group className="mb-3">
              <Form.Label>Old Password</Form.Label>
              <div className="position-relative">
                <span className="lock">
                  <LockIcon size={16} />
                </span>
                <Form.Control
                  type="Password"
                  placeholder="Type here"
                  className="gray-input"
                  value={this.state.oldPassword}
                  onChange={(e) =>
                    this.setState({ oldPassword: e.target.value })
                  }
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <div className="position-relative">
                <span className="lock">
                  <LockIcon size={16} />
                </span>
                <Form.Control
                  type="password"
                  placeholder="Type here"
                  className="gray-input"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label>Confirm New Password</Form.Label>
              <div className="position-relative">
                <span className="lock">
                  <LockIcon size={16} />
                </span>
                <Form.Control
                  type="password"
                  placeholder="Type here"
                  className="gray-input"
                  value={this.state.confirmPasword}
                  onChange={(e) =>
                    this.setState({ confirmPasword: e.target.value })
                  }
                />
              </div>
            </Form.Group>

            <div className="text-center mx-auto">
              <Button
                variant="primary"
                className="rounded-pill w-200"
                type="submit"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
