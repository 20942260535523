import React, { Component } from "react";
import { Col, Container, Row, Card, Button, Accordion } from "react-bootstrap";
import { ChevronLeftIcon, CheckCircleIcon } from "@primer/octicons-react";
import Header from "./Header";
import { Link, Redirect } from "react-router-dom";
import { getData, putData } from "../../utils/fetchAPI";

export default class JourneyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      JourneyDetails: [],
    };
  }
  componentDidMount = async () => {
    try {
      const result = await getData(
        `operator/journeys/${this.props.match.params.name}`
      );
      this.setState({ JourneyDetails: result.data });
    } catch (error) {
      this.setState({ error: true });
    }
  };

  markStoryCompleted = async (e, story, index) => {
    e.preventDefault();

    const newJourneyDetail = { ...this.state.JourneyDetails };
    story.complete = true;
    newJourneyDetail.stories[index] = story;
    console.log(newJourneyDetail);

    try {
      await putData(`operator/journeys/`, newJourneyDetail);
      this.setState({ JourneyDetails: newJourneyDetail });
    } catch (error) {
      this.setState({ error: true });
    }
  };

  render() {
    if (this.state.error) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <>
        <Header currentUser={this.props.currentUser} />
        <div className="search-bg py-4">
          <Container>
            <Row>
              <Col lg={8}>
                <Card className="radius-30 border-0 pb-4 card-box mb-3">
                  <div className="border-bottom title-box p-4 pb-3 d-flex align-items-center">
                    <span className="backup me-3">
                      <Link to="/journey">
                        <ChevronLeftIcon size={16} />
                      </Link>
                    </span>
                    <h5 className="mb-0 text-journey">
                      {this.state.JourneyDetails.name}
                      <p className="mb-0">
                        Started Date :{" "}
                        {new Date(
                          this.state.JourneyDetails.started
                        ).toDateString()}
                      </p>
                    </h5>
                  </div>
                  <div className="px-4 py-3 descipton-text">
                    <p>{this.state.JourneyDetails.description}</p>
                    <div className="status mt-4 border-bottom pb-3 d-flex align-items-center">
                      <span className="font-big me-2">Tactic :</span>
                      <Button
                        variant="false"
                        className="btn-tactic ms-3 pt-1 pb-1"
                      >
                        {this.state.JourneyDetails.tactic}
                      </Button>
                    </div>
                    <div className="status mt-4 border-bottom pb-3 d-flex align-items-center">
                      <span className="font-big me-2">Depends on : </span>
                      {this.state.JourneyDetails.depends_on?.map(
                        (depend, idx) => {
                          return <p key={idx}>{depend}</p>;
                        }
                      )}
                    </div>
                    <div className="status mt-4 border-bottom pb-3 d-flex align-items-center">
                      <span className="font-big me-2">
                        Defensive Techniques :
                      </span>
                    </div>
                    <div className="d-alzt mt-3">
                      {this.state.JourneyDetails.defensive_techniques?.map(
                        (defence, idx) => {
                          return (
                            <Button
                              variant="false"
                              href="#"
                              className="btn-d3 me-2"
                              key={idx}
                            >
                              {defence}
                            </Button>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <div className="border-bottom title-box p-4 pb-3 d-flex align-items-center">
                    <h5 className="mb-0 text-journey">Stories</h5>
                  </div>
                  <div className="p-4">
                    <Accordion>
                      {this.state.JourneyDetails.stories?.map(
                        (story, index) => {
                          return (
                            <Accordion.Item
                              eventKey={index}
                              className="border-0 cricle-according-btn mb-3"
                              key={index}
                            >
                              <Accordion.Header>
                                {story.name}
                                {story.complete ? (
                                  <button className="rounded-pill pt-1 px-3 py-1 ms-3 btn btn-outline-success">
                                    Completed
                                  </button>
                                ) : null}
                              </Accordion.Header>
                              <Accordion.Body>
                                <p>{story.description}</p>
                                <div className="border-bottom title-box p-2 m-left m-right mt-3 pb-2">
                                  <h5 className="mb-0 text-journey">
                                    Stage : {story.stage}
                                  </h5>
                                </div>
                                <div className="border-bottom title-box p-2 m-left m-right mt-3 pb-2">
                                  <h5 className="mb-0 text-journey">
                                    References
                                  </h5>
                                </div>
                                <div className="title-box p-2 m-left m-right mt-2 pb-2">
                                  {story.references?.map((reference, index) => {
                                    return (
                                      <p>
                                        <Link to={reference}>{reference}</Link>
                                      </p>
                                    );
                                  })}
                                </div>
                                <div className="border-bottom title-box p-2 m-left m-right mt-3 pb-2 mb-3">
                                  <h5 className="mb-0 text-journey">
                                    Defensive Techniques
                                  </h5>
                                </div>

                                <Accordion>
                                  {story.defensive_techniques?.map(
                                    (defensive_technique, index) => {
                                      return (
                                        <Accordion.Item
                                          eventKey={index}
                                          className="border-0 cricle-according-btn white-toggle-bg mb-3"
                                        >
                                          <Accordion.Header>
                                            D3-AZET
                                          </Accordion.Header>
                                          <Accordion.Body className="p-0">
                                            <div className="border-top p-3 accordion-text">
                                              <h3>
                                                {" "}
                                                {defensive_technique.name}
                                              </h3>
                                              <p>
                                                {
                                                  defensive_technique.description
                                                }
                                              </p>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      );
                                    }
                                  )}
                                </Accordion>
                                <div className="text-end">
                                  {!story.complete ? (
                                    <>
                                      <button
                                        variant="false"
                                        className="btn-outline-primary rounded-pill py-1 px-3 me-2"
                                      >
                                        Close
                                      </button>
                                      <button
                                        variant="false"
                                        className="btn-primary rounded-pill py-1 px-3"
                                        onClick={(e) =>
                                          this.markStoryCompleted(
                                            e,
                                            story,
                                            index
                                          )
                                        }
                                      >
                                        <span className="d-flex align-items-center">
                                          {" "}
                                          <CheckCircleIcon
                                            size={16}
                                            className="me-2"
                                          />{" "}
                                          Mark as complete
                                        </span>
                                      </button>
                                    </>
                                  ) : null}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        }
                      )}
                    </Accordion>
                  </div>
                </Card>
              </Col>
              <Col lg={4}>
                {/* <Card className="radius-30 border-0 card-box mb-3">
                  <h5 className="p-4 mb-0 border-bottom  pb-3">Breakdown</h5>
                  <Card.Body className="p-4  pt-3">
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem.
                    </p>
                  </Card.Body>
                </Card> */}
                <Card className="radius-30 border-0 card-box mb-3">
                  <h5 className="p-4 mb-0 border-bottom  pb-3">
                    Threat Summary
                  </h5>
                  <Card.Body className="p-4  pt-3">
                    <p>{this.state.JourneyDetails.threat_summary}</p>
                  </Card.Body>
                </Card>
                {/* <Card className="radius-30 border-0 card-box mb-3">
                  <h5 className="p-4 mb-0 border-bottom  pb-3">References</h5>
                  <Card.Body className="p-4 pt-3">
                    <p>
                      <Link to="https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-2.1">
                        https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-cis-controls.html#securityhub-cis-controls-2.1
                      </Link>
                    </p>
                  </Card.Body>
                </Card> */}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
