import React, { Component } from "react";
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import logo from "../images/logo.png";
import logo1 from "../images/logo-2.png";
import check from "../images/check-circle.svg";
import email from "../images/mail.png";
import lock from "../images/lock.png";
import { auth } from "../utils/firebaseCONFIG";
import { Redirect } from "react-router-dom";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      message: "",
      isRedirect: false,
    };
  }
  handleSubmit = async (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    try {
      await auth.signInWithEmailAndPassword(email, password);
      this.setState({
        email: "",
        password: "",
        message: "Successfully login!",
        isRedirect: true,
      });
    } catch (err) {
      //console.log("error", err);
      this.setState({ message: "Wrong credential !!!" });
    }
  };

  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <>
        <div className="login py-5">
          <Container>
            <div className="login-main">
              <Row className="g-0">
                <Col lg={7} className="d-none d-lg-block">
                  <div className="login-bg">
                    <div className="align-box">
                      <div className="logo mb-4">
                        <Image src={logo} />
                      </div>
                      <h1 className="mb-4">
                        WELCOME TO
                        <br /> <b>DISCIPLE</b>
                      </h1>
                      <Button variant="link" className="login-btn">
                        SIGN IN
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg={5} className="border">
                  <div className="login-right p-5">
                    <div className="logo-right pe-lg-5 ps-lg-5">
                      <Image src={logo1} className="mb-5" />
                      <h3 className="mb-4">BE ONE OF US!</h3>
                      <Form>
                        <Form.Group className="mb-3 position-relative">
                          <span className="email-icon ">
                            <Image src={email} />
                          </span>
                          <Form.Control
                            className="login-control"
                            type="email"
                            autoComplete="off"
                            placeholder="disciple@disciple.com"
                            required
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                          <span className="icon-lock">
                            <Image src={check} />
                          </span>
                        </Form.Group>

                        <Form.Group className="mb-3 position-relative">
                          <span className="lock-icon">
                            <Image src={lock} />
                          </span>
                          <Form.Control
                            autoComplete="off"
                            className="login-control"
                            type="password"
                            placeholder="Password"
                            required
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                          />
                          <span className="icon-lock">
                            <Image src={check} />
                          </span>
                          <Form.Label>{this.state.message}</Form.Label>
                        </Form.Group>
                        <div className="d-grid mt-5">
                          <Button
                            onClick={this.handleSubmit}
                            variant="primary"
                            type="submit"
                            size="lg"
                            className="rounded-pill"
                          >
                            LOGIN
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
