import React, { Component } from "react";
import { Image, Button, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logout from "../../images/logout.png";
import { auth } from "../../utils/firebaseCONFIG";

export default class SidebarMenu extends Component {
  render() {
    return (
      <>
        <div className="sidebar-menu">
          <Nav as="ul" className="d-block">
            <Nav.Item as="li">
              <NavLink to="/dashboard" activeClassName="active" exact>
                Journeys
              </NavLink>
            </Nav.Item>
            <Nav.Item as="li">
              <NavLink to="/infrastructure" activeClassName="active" exact>
                Infrastructure Insights
              </NavLink>
            </Nav.Item>
            <Nav.Item as="li">
              <NavLink to="/endpoint-monitoring" activeClassName="active" exact>
                Endpoint Monitoring
              </NavLink>
            </Nav.Item>
          </Nav>
          <div className="logout text-center p-4">
            <Button
              variant="link"
              href="/"
              className="rounded-pill btn-log"
              onClick={() => {
                localStorage.clear();
                return auth.signOut();
              }}
            >
              <span>
                <Image src={logout} />
              </span>
              Logout
            </Button>
          </div>
        </div>
      </>
    );
  }
}
