import React, { Component } from "react";
import {
  Dropdown,
  Navbar,
  Container,
  Nav,
  Image,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import logo1 from "../../images/logo-2.png";
import search from "../../images/search.png";
import user from "../../images/user.png";
import { Redirect, withRouter } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      searchQuery: "",
    };
  }
  componentDidMount() {
    // console.log("pros", this.props);
    this.setState({ searchQuery: this.props.match.params.query || "" });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    // window.location.replace(`/search/${this.state.searchQuery}`);
    this.props.history.push(`/search/${this.state.searchQuery}`);
    // this.setState({ isSearch: true });
  };

  render() {
    if (this.state.isSearch) {
      // this.setState({ isSearch: false });
      return <Redirect to={`/search/${this.state.searchQuery}`} />;
    }
    return (
      <>
        <header>
          <Navbar collapseOnSelect expand="lg" variant="light">
            <Container>
              <Navbar.Brand href="/">
                <Image src={logo1} alt="" style={{ width: "57px" }} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <div className="search-box col-lg-6 ms-lg-4 my-3 my-mb-0">
                  <Form onSubmit={this.handleSubmit}>
                    <InputGroup>
                      <InputGroup.Text>
                        <Image src={search} />
                      </InputGroup.Text>
                      <FormControl
                        className="blue-input"
                        type="search"
                        placeholder="Search..."
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          this.setState({ searchQuery: e.target.value });
                        }}
                        value={this.state.searchQuery}
                      />
                    </InputGroup>
                  </Form>
                </div>
                <Nav className="ms-auto text-end">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="false"
                      className="p-0 dropdown-logo"
                      id="dropdown-basic"
                    >
                      {this.props.currentUser.displayName}
                      <span className="user-profile-toggle ms-3">
                        <Image src={user} alt="user" className="rounded" />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="/">Log Out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default withRouter(Header);
