import React, { Component } from "react";
import { Container, Card, Button } from "react-bootstrap";
import Header from "./Header";
import { Link } from "react-router-dom";
import { getData } from "../../utils/fetchAPI";

export default class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incompleteJourney: [],
      completeJourney: [],
    };
  }

  componentDidMount = async () => {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 992px)").addListener(handler);

    try {
      const result = await getData("operator/journeys/");

      const incomplete = [];
      const complete = [];
      const journey = result.data.journeys;

      for (const i in journey) {
        if (journey[i].finished) {
          journey[i].finished = "Complete";
          complete.push(journey[i]);
        } else {
          journey[i].finished = "Inprocess";
          incomplete.push(journey[i]);
        }
      }

      this.setState({
        completeJourney: complete,
        incompleteJourney: incomplete,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <>
        <Header currentUser={this.props.currentUser} />
        <div className="search-bg py-4">
          <Container>
            <Card className="radius-30 border-0 pb-4 card-box">
              <div className="border-bottom title-box p-4 pb-3">
                <h5 className="mb-0">Current Journey</h5>
              </div>

              <div className="p-4">
                {this.state.incompleteJourney.length
                  ? this.state.incompleteJourney.map((current, index) => {
                      if (current.name) {
                        return (
                          <div
                            className="d-md-flex align-items-center justify-content-between mb-3 blue-light-color p-3 text-md-center md-full"
                            key={index}
                          >
                            <h6 className="mb-3 md-mb-3">
                              {" "}
                              <Link to={`/journey-details/${current.name}`}>
                                {current.name}
                              </Link>
                            </h6>
                            <Button
                              variant="outline-success"
                              className="rounded-pill pt-1 pb-1 "
                            >
                              {current.finished}
                            </Button>
                          </div>
                        );
                      }
                      return "";
                    })
                  : "No Incomplete Journey"}
              </div>
              <div className="border-bottom title-box p-4 pb-3 pt-0">
                <h5 className="mb-0">Completed Journey</h5>
              </div>
              <div className="p-4">
                {this.state.completeJourney.length
                  ? this.state.completeJourney.map((completed, index) => {
                      if (completed.name) {
                        return (
                          <div
                            className="d-md-flex align-items-center justify-content-between mb-3 blue-light-color p-3 md-full"
                            key={index}
                          >
                            <h6 className="mb-3 md-mb-3">
                              <Link to={`/journey-details/${completed.name}`}>
                                {completed.name}
                              </Link>
                            </h6>
                            <Button
                              variant="outline-success"
                              className="rounded-pill pt-1 pb-1"
                            >
                              {completed.finished}
                            </Button>
                          </div>
                        );
                      }
                      return "";
                    })
                  : "No Journey Complete"}
              </div>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}
