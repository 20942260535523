import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Login from "./component/Login";

import Dashboard from "./component/Dashboard";
import Search from "./component/search/Search";
import SearchResults from "./component/search/SearchResult";
import JourneyCurrent from "./component/search/JourneyCurrent";
import JourneyDetails from "./component/search/JourneyDetails";
import ComingSoon from "./component/ComingSoon";
import React from "react";
import { auth, createUserProfileDocument } from "./utils/firebaseCONFIG";
import PrivateRoute from "./utils/privateRoute";

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null,
    };
  }

  unsubscribeFromAuth = null;

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (localStorage.getItem("token")) {
      this.setState({ currentUser: JSON.parse(token) });
    } else {
      this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
        if (userAuth) {
          const userRef = await createUserProfileDocument(userAuth);
          userRef.onSnapshot((snapShot) => {
            console.log(snapShot.id, snapShot.data());
            this.setState({
              currentUser: {
                id: snapShot.id,
                ...snapShot.data(),
              },
            });
          });
          localStorage.setItem("token", JSON.stringify(userAuth));
        }
        this.setState({ currentUser: userAuth });
        // createUserProfileDocument(user);
      });
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) =>
                this.state.currentUser ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <Login {...props} />
                )
              }
            />

            <PrivateRoute
              path="/dashboard"
              exact
              component={Dashboard}
              isLogin={this.state.currentUser}
            />
            <PrivateRoute
              path="/search/:query"
              exact
              component={Search}
              isLogin={this.state.currentUser}
            />
            <PrivateRoute
              path="/search-results"
              exact
              isLogin={this.state.currentUser}
              component={SearchResults}
            />
            <PrivateRoute
              path="/journey"
              exact
              component={JourneyCurrent}
              isLogin={this.state.currentUser}
            />
            <PrivateRoute
              path="/journey-details/:name"
              exact
              isLogin={this.state.currentUser}
              component={JourneyDetails}
            />
            <PrivateRoute
              path="/infrastructure"
              exact
              component={ComingSoon}
              isLogin={this.state.currentUser}
            />
            <PrivateRoute
              path="/endpoint-monitoring"
              exact
              component={ComingSoon}
              isLogin={this.state.currentUser}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
