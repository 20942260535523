import React, { Component } from "react";
import Header from "./Header";
import logo from "../../images/logo-3.png";
import { Container, Card, Nav, Tab, Row, Col, Image } from "react-bootstrap";
import { ChevronRightIcon } from "@primer/octicons-react";
import { Link } from "react-router-dom";
import { getData } from "../../utils/fetchAPI";

export default class JourneyCurrent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incompleteJourney: [],
      completeJourney: [],
    };
  }

  componentDidMount = async () => {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 992px)").addListener(handler);

    try {
      const completeResult = await getData("operator/journeys/?complete=true");
      const incompleteResult = await getData("operator/journeys/?complete=false");
 
      const completejourney = completeResult.data.journeys;
      const incompletejourney = incompleteResult.data.journeys;

      
      const complete = completejourney.filter(journey=>journey.finished)

      this.setState({
        completeJourney: complete,
        incompleteJourney: incompletejourney,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <>
        <Header currentUser={this.props.currentUser} />
        <div className="search-bg py-4">
          <Container className="box-height">
            <Card className="radius-30 border-0 pb-4 card-box h-300">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="border-bottom p-4 pb-3 title-box d-md-flex align-items-center justify-content-between">
                  <h5 className="mb-0">Journey</h5>
                  <Nav
                    variant="false"
                    className="col-md-3 tabs-status mt-3 mt-md-0"
                  >
                    <Nav.Link eventKey="first" className="w-50">
                      Current
                    </Nav.Link>

                    <Nav.Link eventKey="second" className="w-50">
                      Completed
                    </Nav.Link>
                  </Nav>
                </div>

                <Tab.Content className="p-4">
                  <Tab.Pane eventKey="first">
                    <Row xs={1} md={3} lg={3}>
                      {this.state.incompleteJourney.length
                        ? this.state.incompleteJourney.map((journey, index) => {
                            if (journey.name) {
                              return (
                                <Col className="mb-3">
                                  <Card className="border-30 border-0 light-blue-bg">
                                    <Card.Body>
                                      <Link
                                        to={`/journey-details/${journey.name}`}
                                      >
                                        <Row className="g-0 d-flex align-items-center">
                                          <Col xs={2}>
                                            <Image
                                              src={logo}
                                              className="img-w"
                                            />
                                          </Col>
                                          <Col xs={9}>
                                            <div className="text-journey ps-3 pe-3">
                                              <h6>{journey.name}</h6>
                                              <p>
                                                {new Date(
                                                  journey.started
                                                ).toDateString()}
                                              </p>
                                            </div>
                                          </Col>
                                          <Col xs={1}>
                                            <span className="arrow-journey">
                                              <ChevronRightIcon size={20} />
                                            </span>
                                          </Col>
                                        </Row>
                                      </Link>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              );
                            }
                            return "";
                          })
                        : "No Journey Incomplete"}
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Row xs={1} md={3} lg={3}>
                      {this.state.completeJourney.length
                        ? this.state.completeJourney.map((journey, index) => {
                            if (journey.name) {
                              return (
                                <Col className="mb-3">
                                  <div className="complated">
                                    <Card className="border-30 border-0 light-blue-bg">
                                      <Card.Body>
                                        <Link
                                          to={`/journey-details/${journey.name}`}
                                        >
                                          <Row className="g-0 d-flex align-items-center">
                                            <Col xs={2}>
                                              <Image
                                                src={logo}
                                                className="img-w"
                                              />
                                            </Col>
                                            <Col xs={9}>
                                              <div className="text-journey ps-3 pe-3">
                                                <h6>{journey.name}</h6>
                                                <p>
                                                  {new Date(
                                                    journey.started
                                                  ).toDateString()}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col xs={1}>
                                              <span className="arrow-journey">
                                                <ChevronRightIcon size={20} />
                                              </span>
                                            </Col>
                                          </Row>
                                        </Link>
                                      </Card.Body>
                                    </Card>
                                    <span className="complated-text">
                                      Completed
                                    </span>
                                  </div>
                                </Col>
                              );
                            }
                            return "";
                          })
                        : "No Complete Journey"}
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card>
          </Container>
        </div>
      </>
    );
  }
}
