import React, { Component } from "react";
import { Offcanvas, Image } from "react-bootstrap";
import Sidebar from "./sidebar/Sidebar";
import coming from "../images/comming-soon.svg";
import { ThreeBarsIcon } from "@primer/octicons-react";
export default class ComingSoon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      matches: window.matchMedia("(min-width: 992px)").matches,
    };
  }

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 992px)").addListener(handler);
  }

  handleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  render() {
    return (
      <>
        <Offcanvas
          className="openoffset"
          show={this.state.isOpen}
          onHide={this.handleOpen}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>

          <Sidebar />
        </Offcanvas>
        {this.state.matches && <Sidebar />}
        {!this.state.matches && (
          <span
            onClick={this.handleOpen}
            className="toggle-open mt-5 position-absolute"
          >
            <ThreeBarsIcon size={32} />
          </span>
        )}
        <div className="align-items-center coming-bg d-flex justify-content-center p-4 text-center">
          <Image src={coming} alt="" />
        </div>
      </>
    );
  }
}
